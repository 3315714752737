<template>
  <div class="pictures-bar">
    <div class="title">精彩图库</div>
    <div v-if="mapDepotList" @click.stop="scrollClick($event)">
      <vue-seamless-scroll :data="mapDepotList" :class-option="optionLeft" class="seamless-warp2"
                           :style="{'width':mapDepotList.length*2*320+'px'}">
        <ul class="item">
          <li v-for="(item,index) in mapDepotList" :key="index" >
            <template v-if="mode == 1">
              <img class="img" :src="echoImg(item.url)" alt="" :dataId="item.id" fit="fill">
            </template>
            <template v-else-if="mode == 2">
              <img class="img" :src="echoImg(item.url)" alt="" :dataId="item.id" fit="fill">
              <div class="name" :dataId="item.id">{{item.title}}</div>
            </template>
            <template v-else-if="mode == 3">
              <img class="img" :src="echoImg(item.url)" alt="" :dataId="item.id" fit="fill">
              <div class="name1" :dataId="item.id">{{item.title}}</div>
            </template>
          </li>
        </ul>
      </vue-seamless-scroll>
    </div>

  </div>
</template>

<script>
import vueSeamlessScroll from 'vue-seamless-scroll'
export default {
  name: "Pictures",
  components: {
    vueSeamlessScroll
  },
  data() {
    return {
      mode:2,//1表示不显示标题，2表示在图片下方,3表示在图片底部
      mapDepotList:[
        {id:1,title:'图片1图片1图片1图片1图片1图片1图片1图片1图片1图片1图片1图片1',url:'1974ad48fd2349779497ae57b67147cf/WebAnnexFile/2023/01/11/20230111_2d6e82e34ed74f3cb2f9880d8134bcfa.jpg'},
        {id:2,title:'图片2',url:'1974ad48fd2349779497ae57b67147cf/WebAnnexFile/2023/04/21/20230421_4b3dfcb001924f84b19b88d06a3eef6c.jpg'},
        {id:3,title:'图片3',url:'1974ad48fd2349779497ae57b67147cf/WebAnnexFile/2023/04/21/20230421_8e1a025f0a4e49ffb358a1e56388af44.webp'},
        {id:4,title:'图片4',url:'1974ad48fd2349779497ae57b67147cf/WebAnnexFile/2023/01/11/20230111_317d73481a574fe58812dd7bb41ceab5.jpg'},
      ],
      scrollKey:0,
    }
  },
  computed: {
    optionLeft() {
      return {
        step:0.8,
        direction: 2,// 0向下 1向上 2向左 3向右
        // limitMoveNum: this.mapDepotList.length,// 开始无缝滚动的数据量 this.dataList.length
        // hoverStop: true,
        // openTouch: false,
      };
    },
  },
  created() {
    if(this.mapDepotList.length<5){
      this.mapDepotList.forEach(item=>{
        this.mapDepotList.push(item)
      })
    }
  },
  mounted() {

  },
  methods: {
    scrollClick(e){
      let id = e.target.getAttribute('dataId')
      console.log(id)
    },
  },
};
</script>
<style lang="scss" scoped>
  .pictures-bar{
    width: 100%;
    overflow: hidden;
    margin-top: 30px;
    .title{
      height: 40px;
      line-height: 40px;
      background: #00A384;
      color: #fff;
      font-weight: bold;
      padding-left: 20px;
    }
    .seamless-warp2 {
      //width: 3200px;
      overflow: hidden;
      padding: 20px;
      background: #fff;
      ul.item {
        float: left;
        width: 100%;
        li {
          position: relative;
          float: left;
          margin-right: 20px;
          cursor: pointer;
          .img{
            display: inline-block;
            width: 300px;
            height: 190px;
          }
          .name{
            width: 280px;
            text-align: center;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            color: #333;
            font-weight: bold;
            margin-top: 10px;
            padding: 0 10px;
          }
          .name1{
            position: absolute;
            left: 0;
            bottom: 0;
            background: rgba(0,0,0,.8);
            width: 280px;
            text-align: center;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            color: #fff;
            font-weight: bold;
            padding: 10px;
          }
        }
      }
    }
  }

</style>
