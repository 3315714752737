<template>
  <div class="main-bar" :style="{'background':dataObj.mainBgPurity?dataObj.mainBgColor:'url('+echoImg(dataObj.mainBg)+'no-repeat)'}">
    <div class="mainBox" :style="{'width':dataObj.mainW+'px'}">
      <div class="lunBoTu"
           :style="{'width':(dataObj.mainW-(dataObj.mainNum-1)*30)/dataObj.mainNum+'px'}">
        <el-carousel :interval="3000" arrow="always" height="390px" indicator-position="none">
          <el-carousel-item v-for="(item,index) in bannerList" :key="index">
            <div class="title">{{item.title}}</div>
            <el-image class="img" :src="echoImg(item.url)" @click="goDetails('',item.id)"></el-image>
          </el-carousel-item>
        </el-carousel>
      </div>
      <ul class="ul" v-for="(item,index) in newsList" :key="index"
          :style="{'width':(dataObj.mainW-(dataObj.mainNum-1)*30)/dataObj.mainNum+'px'}">
        <div class="name">{{item.name}}</div>
        <li class="li" v-for="(dates,idxs) in item.children" :key="idxs">{{dates.title}}</li>
      </ul>

      <Pictures/>
      <Partners/>
    </div>


  </div>
</template>

<script>
import Pictures from './pictures'
import Partners from './partners'
export default {
  name: "Index",
  components: {
    Pictures,
    Partners,
  },
  data() {
    return {
      dataObj:{
        mainW:1200,
        mainBg:'1974ad48fd2349779497ae57b67147cf/WebAnnexFile/2023/04/25/20230425_c88ee535cdbc4c6f994940b6f10a47f1.jpg',
        mainBgPurity:false,//logo部分是否为纯色
        mainBgColor:'#999',//logo部分为纯色的颜色
        mainNum:3,

      },
      bannerList:[
        {id:1,title:'2022厦门铁人三项公开赛精选',url:'1974ad48fd2349779497ae57b67147cf/WebAnnexFile/2023/01/11/20230111_2d6e82e34ed74f3cb2f9880d8134bcfa.jpg'},
        {id:2,title:'精彩图片1',url:'1974ad48fd2349779497ae57b67147cf/WebAnnexFile/2023/04/21/20230421_4b3dfcb001924f84b19b88d06a3eef6c.jpg'},
        {id:3,title:'测试111',url:'1974ad48fd2349779497ae57b67147cf/WebAnnexFile/2023/04/21/20230421_8e1a025f0a4e49ffb358a1e56388af44.webp'},
        {id:4,title:'2022厦门铁人三项锦标赛精选',url:'1974ad48fd2349779497ae57b67147cf/WebAnnexFile/2023/01/11/20230111_317d73481a574fe58812dd7bb41ceab5.jpg'},
      ],
      newsList:[
        {id:1,name:'新闻1',children:[{id:11,title:'新闻111',time:'2023-11-11'},{id:12,title:'新闻111',time:'2023-11-11'},{id:13,title:'新闻111',time:'2023-11-11'}]},
        {id:2,name:'新闻2',children:[{id:21,title:'新闻111',time:'2023-11-11'},{id:22,title:'新闻111',time:'2023-11-11'},{id:23,title:'新闻111',time:'2023-11-11'}]},
        {id:3,name:'新闻3',children:[{id:31,title:'新闻111',time:'2023-11-11'},{id:32,title:'新闻111',time:'2023-11-11'},{id:33,title:'新闻111',time:'2023-11-11'}]},
        {id:4,name:'新闻4',children:[{id:41,title:'新闻111',time:'2023-11-11'},{id:42,title:'新闻111',time:'2023-11-11'},{id:43,title:'新闻111',time:'2023-11-11'}]},
        {id:5,name:'新闻5',children:[{id:41,title:'新闻111',time:'2023-11-11'},{id:42,title:'新闻111',time:'2023-11-11'},{id:43,title:'新闻111',time:'2023-11-11'}]},
      ],
    }
  },
  mounted() {

  },
  methods: {

  },
};
</script>
<style lang="scss" scoped>
  .main-bar{
    width: 100%;
    height: auto;
    background-size: 100% 100%;
    overflow: hidden;
    .mainBox{
      display: flex;
      flex-flow:row wrap;
      justify-content: space-between;
      margin: 0 auto;
      .lunBoTu{
        position: relative;
        //width:590px;
        height: 390px;
        display: inline-block;
        cursor: pointer;
        margin-top: 30px;
        .title{
          position: absolute;
          left: 0;
          bottom: 0;
          //width: 100%;
          width: 690px;
          background: rgba(0,0,0,0.6);
          z-index: 99;
          color: #fff;
          height: 40px;
          line-height: 40px;
          padding: 0 20px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .img{
          width: 100%;
          height: 100%;
        }
        /deep/ .el-carousel__arrow{
          background-color:rgba(31,45,61,.4);
        }
        /deep/.el-carousel__indicators{
          position: absolute;
          right: 0;
          bottom: 0;
        }
      }
      .ul{
        //width: 590px;
        height: 390px;
        margin-top: 30px;
        background: #00A384;
        .name{

        }
      }
    }

  }

</style>
